import React, {useState, useEffect} from "react"
import axios from "axios"
import { navigate } from "gatsby"
import styled from "styled-components"

const StyledCard = styled.div`
  width: 340px;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.06);
  z-index: 1;
  padding: 40px;
  width: 100%;
  h1 {
    color: #3c3c3c;
    font-size: 30px;
    font-weight: 600;
    line-height: 43px;
    margin-bottom: 1rem;
    text-align: center;
  }
  p {
    font-size: 13px;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.9rem;
    letter-spacing: -.015rem;
    color: #3c3c3c;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 0 !important;
    text-align: center
  }
`

const StyledForm = styled.form`
  border: none;
  width: 100%;
  margin: 1rem 0;
  .control {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    .label {
      font-size: 13px;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.9rem;
      letter-spacing: -.015rem;
      color: #3c3c3c;
      font-weight: 600;
      display: inline-block;
      margin-bottom: 10px;
      margin-top: 0 !important;
    }
    .input {
      width: 100%;
      display: block;
      font-size: 0.9rem;
      line-height: 1.3;
      color: #495057;
      background-color: #fff !important;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 4px;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
      box-shadow: none;
      padding: 13px 20px;
    }
    .input-inline {
      display: flex;
      width: 100%;
      align-items: center;
      .input {
        width: -webkit-fill-available;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .app-domain {
        font-size: 0.9rem;
        line-height: 1.3;
        padding: 13px 20px 13px 0;
        color: #ccc;
        font-weight: 600;
        border: 1px solid #ced4da;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: none;
      }
    }
  }

  .button {
    width: 100%;
    margin-top: 1rem;
    padding: 13px;
    background-color: #00cee0;
    border-width: 0;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    transition: background 0.3s;
    font-size: 1.5rem;
  }

  .terms {
    margin-top: 1rem;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 13px;
    color: #999;
    text-align: center;
  }
`
const StyledError =  styled.div`
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 40px 0px;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  color: white;
  letter-spacing: 0.5px;
  background: #ff5a6e;
  padding: 14px;
`

const Findteam = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const postDomain = e => {
    e.preventDefault()
    setError("")
    setIsLoading(true)
    axios.post(`https://api-dashboard.hrflow.ai/sf/api/public/team/recovery`, {email: email})
    .then(res => {
      setEmailSent(true)
      setIsLoading(false)
    }).catch(err => {
      setError("No email found")
      setIsLoading(false)
    })
  }
  
  return (
    <>
      {error.length > 0 ? (   <StyledError>{error}</StyledError>): ''}
      {emailSent ? 
      (
        <StyledCard>
          <h1>Reminder sent!</h1>
          <p>
            We’ll send you an email with sign-in links for any Hrflow <br />
            teams associated with the address you enter here
          </p>
        </StyledCard>
      )
      :
      (
        <StyledCard>
          <h1>Sign in</h1>
          <StyledForm  onSubmit={e => postDomain(e)}>
            <div className="control">
              <div className="label">Enter your email address *</div>
              <div className="input-inline">
                <input
                  type="text"
                  className="input"
                  name="email"
                  placeholder="Enter your email address"
                  onChange={event => setEmail(event.target.value)}
                  value={email}
                  required
                />
                {/* <span className="app-domain">.hrflow.ai</span> */}
              </div>
            </div>
            <button type="submit" className="button">{isLoading ? <span className="loader"></span>  : 'Send'}</button>
          </StyledForm>
        </StyledCard>
      )
    }
    </>
  );
}

export default Findteam